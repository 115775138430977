<template>
  <div class="c-app flex-row align-items-center" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }">
    <CContainer>
      <CRow class="justify-content-center login-card">
        <CCol md="8" align="center">
          <img src="../../assets/img/logo_biocloudaurum.png" class="c-sidebar-brand-full"  width="240" style="margin-bottom:1rem;">
        </CCol>
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit="onSubmit">
                  <h1>Login</h1>
                  <p>Sign in to your account</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    v-model="login.name"
                    @input="login.name=login.name.toUpperCase()"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="login.password"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CInput
                        v-model="inputCaptha"
                        placeholder="Verification"
                        required
                      >
                      </CInput>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CInput
                        v-model="captha"
                        disabled
                      /> -->
                      <CButton color="success" style="width:100%;padding:0px" v-on:click="getCaptha"><b style="font-size:22px;">{{captha}} <CIcon name="cil-reload"/></b> </CButton>
                    </CCol>
                    <!-- <CCol col="2" class="text-left">
                        <CButton color="success" v-on:click="getCaptha">Refresh <CIcon name="cil-reload"/></CButton>
                    </CCol> -->
                  </CRow>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton  type="submit" color="primary" class="px-4">Login</CButton>
                    </CCol> 
                    <CCol col="6" class="text-right">
                      <!-- <router-link to="/pages/reset-password">
                        <CButton class="px-0" style="color:white;">Forgot password?</CButton>
                      </router-link> -->
                      <CButton class="px-0" style="color:white;" v-on:click="resetModal = true">Forgot password?</CButton>
                      <!-- <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Biocloud Aurum</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>

    <CModal
      title="Warning"
      color="warning"
      :show.sync="warningModal"
    >
      <p v-html="warningMsg"/>
    </CModal>

    <CModal
      title="Request Forgot Password"
      color="danger"
      :show.sync="resetModal"
    >
      <CForm @submit="submitReset">
        <CRow>
          <CCol col="12">
            <CInput
              placeholder="Username"
              v-model="resetPass.username"
              @input="resetPass.username=resetPass.username.toUpperCase()"
              required
            />
          </CCol>
          <CCol col="12">
            <CInput
              placeholder="Email"
              type="email"
              v-model="resetPass.email"
              required
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <CButton  type="submit" color="primary" class="px-4">Submit</CButton>
          </CCol>
        </CRow>
      </CForm>
    </CModal>

    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Login',
  data(){
    return{
      urlApi:this.$store.state.urlApi,
      login:{
        name:null,
        password:null
      },
      resetPass:{
        username:'',
        email:''
      },
      otp_login:{
        remember_otp:null
      },
      inputCaptha:null,
      warningModal: false,
      resetModal:false,
      warningMsg:null,
      captha:null,
      display:true,
      displayLoading:false,
      bgImage:'https://api-biocloudaurum.bisnisonlinebgs.com/uploads/bg-login.jpeg'
    }
  },
  mounted(){
   this.getCaptha()
  },
  methods:{
    onSubmit(e){
      e.preventDefault()

      if(this.inputCaptha == this.captha){
        axios.post(`${this.urlApi}/api/login`,this.login).then(response => {
          localStorage.setItem("LoggedUser", "true")
          localStorage.setItem("id", response.data.user.id)
          localStorage.setItem("name", response.data.user.name)
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("role", response.data.user.role)
          localStorage.setItem("status", response.data.status)
          // this.$router.push({ name: 'Dashboard' })

          location.href = '/'; 
          

        }).catch(function (error) {
          alert('Username atau Password salah / Wrong username or password')
          location.reload()
        })
      } else {
        this.warningModal = true
        this.warningMsg = 'Harap periksa verifikasi <br> Wrong verification'
      }


    },
    getCaptha(){
      let char = "1234567890";
      let lenString = 5;
	    let randomstring = '';

      for (var i=0; i<lenString; i++) {
        let rnum = Math.floor(Math.random() * char.length);
        randomstring += char.substring(rnum, rnum+1);
      }

      this.captha = randomstring
    },

    crateOtp(){
      let char = "1234567890";
      let lenString = 4;
	    let randomstring = '';

      for (var i=0; i<lenString; i++) {
        let rnum = Math.floor(Math.random() * char.length);
        randomstring += char.substring(rnum, rnum+1);
      }

      this.otp_login.remember_otp = randomstring
    },

    submitReset(e){
      e.preventDefault()
      this.resetModal = false

      axios.get(`${this.urlApi}/api/user/getUserByUsernameEmail/${this.resetPass.username},${this.resetPass.email}`).then(response => {
        if(response.data.jumalah>0){
          
          // axios.get(`${this.urlApi}/api/send-email/sendingEmail/${this.resetPass.username},${this.resetPass.email}`).then(response => {

          // })

          this.crateOtp()

          // console.log(this.otp_login.remember_otp)

          axios.get(`${this.urlApi}/api/user/getUserByUsernameEmailData/${this.resetPass.username},${this.resetPass.email}`).then(responseDataByEmail => {

            axios.put(`${this.urlApi}/api/user-details/${responseDataByEmail.data.id}`,this.otp_login).then(responseUpdate => {

              axios.get(`https://api-biocloudaurum.bisnisonlinebgs.com/api/send-email/new/${this.resetPass.username},${this.resetPass.email},${this.otp_login.remember_otp}`).then(response => {

                this.warningModal = true
                this.warningMsg = "Mohon periksa email anda (kotak masuk atau spam) <br > Please check your email (inbox or spam)"

              })
              
            })
          })

          // axios.get(`https://api-biocloudaurum.bisnisonlinebgs.com/api/send-email/new/${this.resetPass.username},${this.resetPass.email},2589`).then(response => {

          // })


          // console.log(this.resetPass.username)

          var string = this.resetPass.username;

          var encodedString = btoa(string);
          // console.log(encodedString); 

          // console.log(atob('aGFwcHk='))

          
        } else {
          this.warningModal = true
          this.warningMsg = "username dan email tidak ditemukan. harap hubungi cs kami <br > username and email not found. please contact our cs"
        }
      })

    }

  }
}
</script>